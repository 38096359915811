import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Title from "../components/Title"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"
import Services from "../components/Services"
import JobsOther from "../components/JobsOther"
import { AiFillTool } from "react-icons/ai"
import { FaBrain } from "react-icons/fa"

const About = ({
  data: {
    about: { nodes },
  },
}) => {
  const { info, stack, title, image } = nodes[0]
  const image2 = getImage(image)
  return (
    <Layout>
      <SEO title="About Me" description="about webdev" />
      <section className="about-page">
        <div className="section-center about-left">
          <GatsbyImage image={image2} alt="me" className="about-img" />
          <article className="about-text">
            <Title title={title} />
            <div dangerouslySetInnerHTML={{__html: info.childMarkdownRemark.html} } />
              <div className="about-stack">
                {stack.map(({ node }, i) => (
                  <span><div key={i}>• {stack[i]}</div></span>
                ))}
              </div>

            <p> </p>
            <details>
<summary> <AiFillTool/> My current toolset (click to unfold)</summary>
<img alt="MySQL" height="24px" src="/svg/mysql.svg" /> MySQL
&nbsp; <img alt="Windows Server" height="24px" src="/svg/windows.svg" /> Windows Server
&nbsp; <img alt="Hyper-V" height="24px" src="/svg/microsoft.svg" /> Hyper-V
&nbsp; <img alt="Docker" height="24px" src="/svg/docker.svg" /> Docker
&nbsp; <img alt="Bash/Powershell" height="24px" src="/svg/powershell.svg" /> Bash/Powershell
&nbsp; <img alt="Linux" height="24px" src="/svg/linux.svg" /> Linux
&nbsp; <img alt="Apache" height="24px" src="/svg/apache.svg" />Apache
&nbsp; <img alt="Cloud" height="24px" src="/svg/icloud.svg" /> Cloud infrastructure
&nbsp; <img alt="Git/SVN" height="24px" src="/svg/git.svg" /> Git/SVN
&nbsp; <img alt="Tomcat" height="24px" src="/svg/server.svg" /> Tomcat
&nbsp; <img alt="Maven" height="24px" src="/svg/apachemaven.svg" />Maven
&nbsp; <img alt="Java" height="24px" src="/svg/java.svg" /> Java
&nbsp; <img alt="Spring" height="24px" src="/svg/spring.svg" /> Spring
&nbsp; <img alt="Eclipse" height="24px" src="/svg/eclipseide.svg" /> Eclipse
&nbsp; <img alt="VS Code" height="24px" src="/svg/visualstudiocode.svg" /> VS Code
&nbsp; <img alt="Chrome" height="24px" src="/svg/googlechrome.svg" /> Chrome DevTools
&nbsp; <img alt="Selenium" height="24px" src="/svg/selenium.svg" /> Selenium
&nbsp; <img alt="Postman" height="24px" src="/svg/postman.svg" /> Postman
&nbsp; <img alt="Jenkins" height="24px" src="/svg/jenkins.svg" /> Jenkins
&nbsp; <img alt="Crystal Reports" height="24px" src="/svg/invoice.svg" /> Crystal Reports
&nbsp; <img alt="Keycloak" height="24px" src="/svg/userauthentication.svg" /> Keycloak
&nbsp; <img alt="Elasticsearch" height="24px" src="/svg/elasticsearch.svg" /> Elasticsearch
&nbsp; <img alt="Node-RED" height="24px" src="/svg/node-red.svg" /> Node-RED
&nbsp; <img alt="RocketChat" height="24px" src="/svg/rocketchat.svg" /> RocketChat
&nbsp; <img alt="JIRA" height="24px" src="/svg/jira.svg" /> JIRA
&nbsp; <img alt="Swagger" height="24px" src="/svg/openapiinitiative.svg" /> Swagger
</details>
<p> </p>

<details open>
<summary> <FaBrain/> Side projects</summary>
<p></p>
&nbsp; <strong> • Software development:</strong> <img alt="" height="24px" src="/svg/java.svg"/> Java / <img alt="" height="24px" src="/svg/spring.svg"/> Spring 
/ <img alt="" height="24px" src="/svg/react.svg"/> React, I'm developing <a href="/projects">web applications</a> for knowledge workers, for educators, for entrepreneurs.
<p></p>
&nbsp; <strong> • IT infrastructure:</strong> AWS / Azure / Hyper-V / Linux / Docker / Apache / Networking
</details>

</article>
      </div>
      </section>
      <JobsOther />
      <Services />
      <div className="section bg-grey">
      <Link to="/" className="btn center-btn">
        go back
      </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  { 
    about: allContentfulAbout {
      nodes {
        title
        stack
        info {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImage(width: 240)
        }

      }
    }
  }
`

export default About
